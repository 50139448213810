import React, { createContext, useState } from "react";
import api from "../Api"; // Axios instance

export const GamerContext = createContext();

export const GamerProvider = ({ children }) => {
  const [gamer, setGamer] = useState({
    score: 0, // Default score is 0
    ownedBoosts: [], // Default owned boosts is an empty array
  });

  // Function to fetch updated gamer data from the database
  const refreshGamerData = async () => {
    try {
      const response = await api.get("/gamer"); // Assuming you have an API route to get the gamer data
      const updatedGamer = response.data.gamer;
      console.log(updatedGamer);
      setGamer({
        score: updatedGamer.score,
        ownedBoosts: updatedGamer.ownedBoosts,
      });
    } catch (error) {
      console.error("Failed to refresh gamer data:", error);
    }
  };

  return (
    <GamerContext.Provider value={{ gamer, setGamer, refreshGamerData }}>
      {children}
    </GamerContext.Provider>
  );
};
