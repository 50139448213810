import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { GamerContext } from './GamerContext'; // Import GamerContext
import api from '../Api'; // Import the API module

const TimerContext = createContext();

const TimerProvider = ({ children }) => {
  const { gamer, setGamer } = useContext(GamerContext); // Access gamer data from GamerContext

  const initialPPH = gamer?.pph || 500;
  const initialgamerScore = gamer?.score || 0;

  // Fallback for initialLastClaim
  const initialLastClaimString = gamer?.last_claim || new Date().toISOString();
  const initialLastClaim = new Date(initialLastClaimString.replace(' ', 'T')).getTime();

  const maxTime = 4 * 60 * 60;
  const timeSinceLastClaim = Math.floor((Date.now() - initialLastClaim) / 1000);

  const [timeElapsed, setTimeElapsed] = useState(Math.max(0, maxTime - timeSinceLastClaim)); // Ensure non-negative
  const [gamerScore, setgamerScore] = useState(initialgamerScore); // Start with the user's current score
  const [isScaling, setIsScaling] = useState(false);
  const [claimCounter, setClaimCounter] = useState(0);
  const [showClaim, setShowClaim] = useState(false);

  useEffect(() => {
    if (timeElapsed > 0) {
      const timer = setInterval(() => {
        setTimeElapsed((prevTime) => Math.max(0, prevTime - 1)); // Ensure non-negative
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeElapsed]);

  const getPercentage = useCallback(() => {
    return ((maxTime - timeElapsed) / maxTime) * 100;
  }, [timeElapsed, maxTime]);

  const calculateScoreToAdd = useCallback(() => {
    const elapsedHours = (maxTime - timeElapsed) / 3600;
    const scoreToAdd = elapsedHours * initialPPH;
    return isNaN(scoreToAdd) ? 0 : scoreToAdd; // Fallback to 0 if NaN
  }, [timeElapsed, initialPPH, maxTime]);

  const handleClaimClick = useCallback(() => {
    const scoreToAdd = calculateScoreToAdd();
    if (isNaN(scoreToAdd) || scoreToAdd <= 0) {
      console.error('Invalid scoreToAdd:', scoreToAdd);
      return;
    }

    setIsScaling(true);
    setShowClaim(true);

    api.post('/claim', { scoreToAdd })
      .then(response => {
        const { newScore, newLastClaim, newPPH } = response.data;

        // Update GamerContext with the new data
        setGamer(prevGamer => ({
          ...prevGamer,
          score: newScore,
          last_claim: newLastClaim,
          pph: newPPH,
        }));

        let currentScore = gamerScore;
        const increment = Math.ceil(scoreToAdd / 100);

        const animationInterval = setInterval(() => {
          if (currentScore < newScore) { // Compare against newScore from the response
            currentScore = Math.min(currentScore + increment, newScore);
            setgamerScore(currentScore);
          } else {
            setgamerScore(newScore);
            clearInterval(animationInterval);
            setIsScaling(false);
          }
        }, 20);

        setTimeElapsed(maxTime); // Reset the timer
        setClaimCounter((prev) => prev + 1);
      })
      .catch(error => {
        console.error("Error during claim:", error);
        setIsScaling(false);
      });
  }, [timeElapsed, gamerScore, maxTime, calculateScoreToAdd, setGamer]);

  return (
    <TimerContext.Provider
      value={{
        maxTime,
        timeElapsed,
        gamerScore,
        isScaling,
        setIsScaling,
        claimCounter,
        handleClaimClick,
        showClaim,
        getPercentage,
        calculateScoreToAdd,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export { TimerContext, TimerProvider };
