import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Formatter from "./Formatter";
import Loading from "../components/Loading";
import api from "../Api"; // Axios instance
import { GamerContext } from "../contexts/GamerContext"; // Import the context

const Plans = () => {
  const [boosts, setBoosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPlanId, setLoadingPlanId] = useState(null);

  // Fetch gamer data and refresh function from GamerContext
  const { gamer, refreshGamerData } = useContext(GamerContext); 

  // Function to fetch the boost plans
  const fetchBoostPlans = () => {
    api.get("/boosts")
      .then((response) => {
        setBoosts(response.data.data); // Assuming boosts are in response.data.data
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Failed to load plans. Please try again later.");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBoostPlans(); // Fetch boost plans on component mount
  }, []);

  const handleBuyClick = (planId) => {
    setLoadingPlanId(planId);

    // API call to buy the boost
    api.post(`/booster/${planId}`)
      .then(async (response) => {
        // Purchase is successful
        toast.success("Purchase successful!", {
          position: "top-center",
          autoClose: 3000,
        });

        // Refresh gamer data (fetch the latest score and owned boosts from the database)
        await refreshGamerData();

        // Re-fetch boost plans based on the updated gamer score
        fetchBoostPlans();

        setLoadingPlanId(null);
      })
      .catch(() => {
        toast.error("Insufficient funds!", {
          position: "top-center",
          autoClose: 3000,
        });
        setLoadingPlanId(null);
      });
  };

  if (loading) {
    return <Loading />;
  }

  // Sort the boosts so that buyable come first, then owned, then unaffordable
  const sortedBoosts = [...boosts].sort((a, b) => {
    const userOwnsA = a.user_has_the_boost;
    const userOwnsB = b.user_has_the_boost;
    const canAffordA = gamer?.score >= a.price;
    const canAffordB = gamer?.score >= b.price;

    // Buyable first (can afford and doesn't own)
    if (!userOwnsA && canAffordA && (userOwnsB || !canAffordB)) return -1;
    if (!userOwnsB && canAffordB && (userOwnsA || !canAffordA)) return 1;

    // Owned second
    if (userOwnsA && !userOwnsB) return 1;
    if (userOwnsB && !userOwnsA) return -1;

    // Unaffordable last
    if (!canAffordA && canAffordB) return 1;
    if (!canAffordB && canAffordA) return -1;

    return 0;
  });

  return (
    <div className="overflow-y-scroll hide-scrollbar" style={{ maxHeight: "calc(100vh - 150px)" }}>
      <div className="grid grid-cols-2 gap-4">
        {sortedBoosts.map((plan, index) => {
          const userOwnsThis = plan.user_has_the_boost; // Use the user_has_the_boost field directly from the API response
          const canAfford = gamer?.score >= plan.price; // Check if the user has enough score

          let buttonText = "Buy";
          let buttonDisabled = false;
          let buttonClass = "bg-gradient-to-r from-purple-500 to-blue-500";
          let cardOpacity = 1;
          let buttonOpacity = 1;

          if (userOwnsThis) {
            // If user owns the boost
            buttonText = "You own this";
            buttonDisabled = true;
            buttonClass = "bg-orange-400 cursor-not-allowed";
          } else if (!canAfford) {
            // If user cannot afford the boost
            cardOpacity = 0.5;
            buttonDisabled = true;
            // Keep the background like a "Buy" button but reduce opacity
            buttonClass = "bg-gradient-to-r from-purple-500 to-blue-500";
            buttonOpacity = 0.5;
          }

          return (
            <motion.div
              key={plan.id}
              className={`p-4 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg flex flex-col items-center ${
                userOwnsThis ? "border-2 border-purple-500" : ""
              }`}
              style={{ opacity: cardOpacity }} // Set opacity for the entire card
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <img
                src={plan.icon} // Assuming the image URL is included in your API response
                alt={plan.pph}
                className="w-20 h-20 object-cover mb-2"
                style={{ opacity: cardOpacity }} // Set opacity for the image
              />
              <h3 className="text-sm font-bold mb-2">
                <span
                  className="bg-gradient-to-r from-orange-600 to-yellow-600 text-transparent bg-clip-text"
                  style={{ opacity: cardOpacity }} // Set opacity for the PPH
                >
                  +<Formatter number={plan.pph} formatType="separator" />
                </span>
                <span className="text-xs opacity-30" style={{ opacity: cardOpacity }}>
                  {" "}
                  / PPH
                </span>
              </h3>
              {plan.price !== undefined ? (
                <div
                  className="text-xl font-bold mb-4 flex items-center justify-center"
                  style={{ opacity: cardOpacity }}
                >
                  <img
                    src="./assets/img/coin-simple.webp"
                    alt="Griffin"
                    className="h-5 mr-2 inline-block"
                  />
                  <Formatter number={plan.price.toString().replace(/,/g, "")} formatType="number" />
                </div>
              ) : (
                <p className="text-xl font-bold mb-4" style={{ opacity: cardOpacity }}>
                  Price not available
                </p>
              )}
              <button
                className={`w-full py-2 text-white font-bold text-sm rounded-full shadow-lg flex items-center justify-center ${buttonClass}`}
                style={{ opacity: buttonOpacity }} // Set opacity for the button
                disabled={buttonDisabled || loadingPlanId === plan.id}
                onClick={() => handleBuyClick(plan.id)}
              >
                {loadingPlanId === plan.id ? <Loading /> : buttonText}
              </button>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default Plans;
