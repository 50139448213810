import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import PropTypes from "prop-types";
import api from "../../Api"; // Import the Axios instance for API requests
import Formatter from "../Formatter";

const DirectRefModal = ({ isModalOpen, closeModal }) => {
  const [referrals, setReferrals] = useState([]); // State to store referrals
  const [loading, setLoading] = useState(true);  // State to manage loading

  const modalStyle = {
    width: "100%",
    height: "70vh",
    display: "block", // Always show the modal container
  };

  const modalAnimation = useSpring({
    transform: isModalOpen ? `translateY(0%)` : `translateY(100%)`,
    opacity: isModalOpen ? 1 : 0,
    config: { duration: 150 }, // Animation duration for opening
  });

  const closeModalWithAnimation = () => {
    closeModal(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      // Fetch direct referrals when the modal is opened
      api.get('/invitation/direct-referrals')
        .then((response) => {
          setReferrals(response.data); // Store the fetched referrals
          setLoading(false); // Stop loading once data is fetched
        })
        .catch((error) => {
          console.error("Error fetching referrals:", error);
          setLoading(false); // Stop loading even in case of error
        });
    }
  }, [isModalOpen]);

  return (
    <animated.div
      style={{ ...modalStyle, ...modalAnimation }}
      className="fixed bottom-0 left-0 right-0 flex items-center justify-center z-50"
    >
      <div
        className="rounded-t-3xl shadow-lg max-w-lg w-full mx-auto p-px bg-gradient-to-b from-purple-500 via-transparent to-transparent"
        style={{ height: "70vh" }}
      >
        <div className="relative rounded-t-3xl px-6 py-2 dark:bg-zinc-900 h-full overflow-hidden">
          <h2 className="text-xl font-bold mb-4 mt-6 text-center">
            Direct referrals
          </h2>
          <button
            onClick={closeModalWithAnimation}
            className="absolute top-3 right-3 p-1 text-gray-600 bg-zinc-800 rounded-full"
          >
            <i data-feather="x"></i>
          </button>
          <div className="rounded-3xl p-6 bg-zinc-800">
            {loading ? (
              <p>Loading referrals...</p>
            ) : referrals.length === 0 ? (
              // No referrals state
              <div className="flex flex-col items-center justify-center h-full relative">
                <img
                  src="./assets/img/no-referrals.webp" // The image displayed when no referrals
                  alt="No Referrals"
                  className="w-40 h-40 mb-4 rounded-2xl" // Added border-radius to the image
                />
                <h3 className="text-xl text-gray-700 dark:text-white mt-4 text-center">
                  You have no direct referral yet
                </h3>
              </div>
            ) : (
              <ul
                className="list-none overflow-auto hide-scrollbar"
                style={{ maxHeight: "calc(70vh - 220px)" }}
              >
                {referrals.map((referral, index) => (
                  <li
                    key={referral.id || index} // Fallback to index if referral.id is missing
                    className="mb-2 py-2 px-4 bg-white bg-opacity-5 backdrop-filter backdrop-blur-lg rounded-lg flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={referral.avatar || "./assets/img/avatar/default-avatar.webp"} // Use default avatar if none is provided
                        alt={referral.tg_username}
                      />
                      <div className="ml-4">
                        <h3 className="text-xs font-medium text-gray-700 dark:text-white">
                          {referral.tg_username}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <img
                        src="./assets/img/coin-simple.webp"
                        alt="Griffin"
                        className="h-4 mr-2"
                      />
                      <span className="font-bold bg-gradient-to-r from-orange-400 to-yellow-600 text-transparent bg-clip-text">
                        <Formatter
                          number={parseInt(referral.total_score)} // Use total_score instead of amount
                          formatType="number"
                        />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="absolute bottom-6 left-0 w-full px-6">
            <button
              onClick={closeModalWithAnimation}
              className="w-full py-4 text-white font-bold rounded-full bg-gradient-to-r from-purple-500 to-purple-900"
            >
              Okey!
            </button>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

DirectRefModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DirectRefModal;
