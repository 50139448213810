import axios from "axios";

// const apiBaseUrl = "https://api.webflaxwizardcom.com/api/";
const apiBaseUrl = "http://127.0.0.1:8000/api/";

// Create an Axios instance
const api = axios.create({
  baseURL: apiBaseUrl,
});

// Add a request interceptor to dynamically set the Authorization header
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token"); // Ensure the key is correct

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    } else {
      console.warn("No access token found. Please log in.");
    }

    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export default api;
