import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CheckCircle } from "react-feather";
import Loading from "../components/Loading"; // Import your custom Loading component
import api from "../Api"; // Import the Axios instance

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Page state for pagination
  const [hasMore, setHasMore] = useState(true); // Track if there are more items to load
  const containerRef = useRef(null);

  const loadTasks = useCallback(() => {
    setLoading(true);
    let endpoint = "";
    if (activeTab === "active") {
      endpoint = "/tasks"; // Endpoint for active tasks
    } else if (activeTab === "completed") {
      endpoint = "/gamer-done-tasks"; // Endpoint for completed tasks
    }

    api
      .get(endpoint, { params: { page } })
      .then((response) => {
        // Assuming the API returns data in response.data.data for paginated results
        const responseData = response.data.data || response.data; // Adjust based on actual API response

        if (activeTab === "active") {
          setTasks((prevTasks) => [...prevTasks, ...responseData]);
        } else {
          setCompletedTasks((prevTasks) => [...prevTasks, ...responseData]);
        }

        // Update pagination status
        setHasMore(response.data.current_page < response.data.last_page);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Failed to load tasks. Please try again later.");
        setLoading(false);
      });
  }, [page, activeTab]);

  useEffect(() => {
    loadTasks();
  }, [page, activeTab, loadTasks]);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setTasks([]);
    setCompletedTasks([]);
    setPage(1);
    setHasMore(true);
  };

  const getImageForPlatform = (platform) => {
    switch (platform.toLowerCase()) {
      case "youtube":
        return "./assets/img/socials/youtube-50.webp";
      case "telegram":
        return "./assets/img/socials/telegram-50.webp";
      case "xcom":
        return "./assets/img/socials/x-50.webp";
      default:
        return "./assets/img/socials/default-50.webp"; // Fallback image
    }
  };

  const handleStartTask = (taskId) => {
    api
      .post(`/tasker/${taskId}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Task completed successfully!");

          // Remove the task from active tasks
          setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));

          // Optionally, add the task to completed tasks
          // Since the task data is not returned in the response, you might need to fetch it again
          // For simplicity, we'll reload the completed tasks
          setCompletedTasks([]);
          setPage(1);
          loadTasks(); // Reload tasks to update the lists
        } else {
          toast.error("Failed to complete task. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error completing task:", error);
        toast.error("Failed to complete task. Please try again later.");
      });
  };

  return (
    <div id="tasks">
      {/* Tabs */}
      <div id="tabs" className="flex justify-center text-center mb-4">
        <button
          onClick={() => handleTabClick("active")}
          className={`tab-toggler flex-grow w-full rounded-s-full px-4 py-2 text-sm ${
            activeTab === "active"
              ? "bg-gradient-to-r from-blue-500 to-cyan-500 text-white"
              : "bg-gray-700 text-white"
          } rounded-l-lg focus:outline-none`}
        >
          Active
        </button>
        <button
          onClick={() => handleTabClick("completed")}
          className={`tab-toggler flex-grow w-full rounded-e-full px-4 py-2 text-sm ${
            activeTab === "completed"
              ? "bg-gradient-to-r from-blue-500 to-cyan-500 text-white"
              : "bg-gray-700 text-white"
          } rounded-l-lg focus:outline-none`}
        >
          Completed
        </button>
      </div>
      <div
        id="tab-contents"
        className="overflow-y-scroll hide-scrollbar"
        style={{ maxHeight: "calc(100vh - 150px)" }}
        onScroll={handleScroll}
        ref={containerRef}
      >
        {/* Task List */}
        {activeTab === "active" && (
          <div id="active-tab">
            <div className="max-w-md w-full mx-auto">
              {tasks.length === 0 && !loading && (
                <p className="text-center text-gray-500">No active tasks available.</p>
              )}
              {tasks.map((task, index) => (
                <motion.div
                  key={task.id || index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2, delay: index * 0.1 }}
                  className="flex items-center p-4 bg-gray-700 rounded-lg mb-2"
                >
                  <img
                    src={getImageForPlatform(task.platform)}
                    alt={task.platform}
                    className="w-12 h-12 rounded-full"
                  />
                  <div className="flex-1 ml-4">
                    <p className="text-xs text-gray-300">{task.title}</p>
                    <p className="bg-gradient-to-r from-orange-400 to-yellow-600 inline-block text-transparent bg-clip-text">
                      +<span className="font-bold">$</span> {task.reward}
                    </p>
                  </div>
                  <button
                    className="inline-block px-4 py-2 text-xs text-white rounded-full bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-gradient-to-l focus:outline-none"
                    onClick={() => handleStartTask(task.id)}
                  >
                    Start
                  </button>
                </motion.div>
              ))}
              {loading && <Loading />}
            </div>
          </div>
        )}
        {activeTab === "completed" && (
          <div id="completed-tab">
            <div className="max-w-md w-full mx-auto">
              {completedTasks.length === 0 && !loading && (
                <p className="text-center text-gray-500">You haven't completed any tasks yet.</p>
              )}
              {completedTasks.map((task, index) => (
                <motion.div
                  key={task.id || index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2, delay: index * 0.1 }}
                  className="flex items-center p-4 bg-gray-700 rounded-lg mb-2 opacity-85"
                >
                  <img
                    src={getImageForPlatform(task.platform)}
                    alt={task.platform}
                    className="w-12 h-12 rounded-full"
                  />
                  <div className="flex-1 ml-4">
                    <p className="text-xs text-gray-300">{task.title}</p>
                    <p className="bg-gradient-to-r from-orange-400 to-yellow-600 inline-block text-transparent bg-clip-text">
                      +<span className="font-bold">$</span> {task.reward}
                    </p>
                  </div>
                  <div className="flex items-center ml-4 text-green-500">
                    <CheckCircle size={14} className="mr-1" />
                    <span className="text-xs">Claimed!</span>
                  </div>
                </motion.div>
              ))}
              {loading && <Loading />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
